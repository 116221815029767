/* eslint-disable camelcase */
'use client'
import React, { useEffect, useRef } from 'react'
import useUserAgent from 'helpers/useUserAgent'
import { useRouter } from 'next/navigation'

import Loading from 'app/components/loading'
// import { adjustLink } from 'helpers/constants'

import styles from './index.module.scss'
import { generateAdjustLink } from 'helpers/adjust'

const AppRedirect = () => {
  const { ua: userAgent } = useUserAgent()
  const router = useRouter()
  const timeoutRef = useRef(
    setTimeout(() => {
      router.push('/')
    }, 30000)
  )
  useEffect(() => {
    if (!userAgent || typeof window == 'undefined') {
      return
    }
    clearTimeout(timeoutRef.current)

    // const search = window.location.search
    const fullURL = generateAdjustLink()
    window.location.replace(fullURL)
  }, [userAgent])

  return (
    <div className={styles.wrapper}>
      <div className={styles.loadingWrapper}>
        <Loading />
      </div>
    </div>
  )
}

export default AppRedirect
