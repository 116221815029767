import { adjustLink } from "./constants"

const adjustMap: Record<string, string> = {
  'utm_source': 'adgroup',
  "utm_medium": "creative",
  "utm_campaign": "campaign"
}

export const generateAdjustLink = (baseLink = adjustLink, mapper = adjustMap, filterKeys: string[] = []) => {
  const searchParams = new URLSearchParams(window.location.search);
  const result = {}
  const hasFilter = Boolean(filterKeys.length)
  searchParams.forEach((value, key) => {
    if (hasFilter && !filterKeys.includes(key)) {
      return
    }
    const mapKey = (mapper?.[key] || key);
    result[mapKey] = value
  })

  const search = Object.keys(result).map(key => {
    return `${key}=${result[key]}`
  })
  return `${baseLink}` + (search ? `?${search.join('&')}` : '')
}